import React from 'react';
import './HeroAlunos.css';

function HeroAlunos({ setShowModalAlunos }) {
    return (
        <section className="hero-alunos">
            <div className="hero-alunos-container">
                <h1>
                    <strong>Alcance o seu próximo<br /> nível de qualificação profissional.</strong> Aqui você<br/> vira Pro de verdade!</h1>
                <p>
                    Probies é o programa gratuito de formação em tecnologia para quem busca uma carreira de sucesso na área. Com nossa formação completa, você estará preparado para enfrentar os desafios do mercado e se destacar.                </p>
                <button onClick={() => setShowModalAlunos(true)}>
                    QUERO ME INSCREVER
                </button>
            </div>
        </section>
    )
}

export default HeroAlunos;