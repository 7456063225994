import React from 'react';
import formation from '../../assets/formationProbies.png'
import coin from '../../assets/coinProbies.png'
import transformation from '../../assets/transformationProbies.png'
import script from '../../assets/scriptProbies.png'
import development from '../../assets/developmentProbies.png'
import online from '../../assets/onlineProbies.png'
import './WhyProbiesAlunos.css';

function WhyProbiesAlunos() {
    return (
        <section className='why-probies-alunos'>
                <div className='why-probies-container'>
                    <h1>
                        Por que se tornar um probie?
                    </h1>
                </div>
                <div className='card-transformation'>
                    <img src={transformation} alt='frontend imagem' />
                    <p>Transforme a sua <br />realidade profissional<br /> e pessoal</p>
                </div>
                <div className='card-formation'>
                    <img src={formation} alt='frontend imagem' />
                    <p>Formação completa <br />e atualizada</p>
                </div>
                <div className='card-development'>
                    <img src={development} alt='frontend imagem' />
                    <p>Desenvolvimento de habilidades de <br />liderança, comunicação e tomada de <br />decisão</p>
                </div>
                <div className='card-script'>
                    <img src={script} alt='frontend imagem' />
                    <p>Aprenda com <br />projetos reais</p>
                </div>
                <div className='card-online'>
                    <img src={online} alt='frontend imagem' />
                    <p><strong>100% online.</strong><br /> Estude no conforto da sua casa.</p>
                </div>
                <div className='card-price'>
                    <img src={coin} alt='moeda' />
                    <p><strong>100% gratuito.</strong><br />O programa é <br />financiado por <br />empresas.</p>
                </div>
        </section>

    )
}

export default WhyProbiesAlunos;