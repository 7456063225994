import React from 'react';
import './WhyProbies.css';

function WhyProbies() {
  return (
    <section className='why'>
      <div className='why-content'>
        <h1 className='why-title'>
          Por que escolher Probies?
        </h1>
        <div className='why-container'>
          <div className='card-why'>
            <p>mais preparados para <br />a rotina de atividades<br /> diárias</p>
          </div>
          <div className='card-why'>
            <p>formados com as boas <br />práticas de mercado</p>
          </div>
          <div className='card-why'>
            <p>com o mínimo de <br />onboarding e integração, <br />já “entram jogando”</p>
          </div>
          <div className='card-why'>
            <p>necessita de orientação <br />e não acompanhamento</p>
          </div>
          <div className='card-why'>
            <p>com potencial de <br />crescimento futuro</p>
          </div>
          <div className='card-why'>
            <p>desenvolvem e testam <br/>códigos, sugerem e buscam melhorias</p>
          </div>
          <div className='card-why'>
            <p>sua empresa como marca empregadora,<br /> formadora e ESG</p>
          </div>
          <div className='card-why'>
            <p>maior previsibilidade de contratação e <br />progressão de carreira</p>
          </div>
          <div className='card-why'>
            <p>redução de turnover e <br />aumento do ROI</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyProbies;
