import React, { useState, useEffect } from 'react'
import './Method.css'
import icon from '../../assets/probies-icon.png'
import upskilling from '../../assets/Upskilling.png'
import reskilling from '../../assets/Reskilling.png'
import diversity from '../../assets/diversity.png'
import probiesBg from '../../assets/probies-bg.png'
import upskillingBg from '../../assets/upskilling-bg.png'
import reskillingBg from '../../assets/reskilling-bg.png'
import diversityBg from '../../assets/diversity-bg.png'

const Method = () => {
  const images = [probiesBg, upskillingBg, reskillingBg, diversityBg];
  images.forEach((image) => {
    const img = new Image();
    img.src = image;
  });

  const [openCard, setOpenCard] = useState(0);

  useEffect(() => {
    const method = document.querySelector('.method');
    if (method) {
      switch (openCard) {
        case 0:
          method.style.backgroundImage = `url(${probiesBg})`;
          break;
        case 1:
          method.style.backgroundImage = `url(${upskillingBg})`;
          break;
        case 2:
          method.style.backgroundImage = `url(${reskillingBg})`;
          break;
        case 3:
          method.style.backgroundImage = `url(${diversityBg})`;
          break;
        default:
          method.style.backgroundImage = `url(${probiesBg})`;
          break;
      }
    }
  }, [openCard]);

  const handleClick = (index) => {
    setOpenCard(index);
  }

  return (
    <section className='method'>
      <div className='method-container'>
        <div className='text-container'>
          <h1>
            Método personalizado, testado <br />
            e aprovado há 15 anos para <br />
            <strong>resultados incríveis</strong>
          </h1>
          <p>
            Oferecemos trilhas e programas de aceleração tecnológicas para empresas que precisam de equipes qualificadas.
          </p>
        </div>
        <div className='description-container'>
          <div className='aceleration-program'>
            <h1>
              Programa de Aceleração
            </h1>
            <p>
              As atividades são dinâmicas, interativas, 100% remotas. A formação é totalmente gratuita para os participantes, com mentorias individuais e coletivas realizadas por profissionais atuantes no mercado. Soft skills também são contempladas: pensamento analítico e criativo, resiliência e flexibilidade, motivação e autoconsciência, curiosidade e aprendizado contínuo, empatia e escuta ativa.
            </p>
            <p>
              São essas interações que garantem que os participantes se tornem profissionais prontos para atender às demandas do trabalho diário até os desafios que possam surgir no mercado.
            </p>
          </div>
          <div className='interactive-card'>
            <div className='card probies' onClick={() => handleClick(0)}>
              <img src={icon} alt='probies icon' />
              <div className='card-text'>
                <h1>Júnior+</h1>
                {openCard === 0 && (
                  <p>Captamos e selecionamos juniores e o aceleramos para atender às necessidades da sua empresa.</p>
                )}
              </div>
            </div>
            <div className='card upskilling' onClick={() => handleClick(1)}>
              <img src={upskilling} alt='upskilling icon' />
              <div className='card-text'>
                <h1>Upskilling</h1>
                {openCard === 1 && (
                  <p>Seus colaboradores da área tech adquirem novas habilidades para manter a sua empresa competitiva no mercado de trabalho.</p>
                )}
              </div>
            </div>
            <div className='card reskilling' onClick={() => handleClick(2)}>
              <img src={reskilling} alt='reskilling icon' />
              <div className='card-text'>
                <h1>Reskilling</h1>
                {openCard === 2 && (
                  <p>Formação para apoiar seus colaboradores em novas possibilidades de carreira, buscando transacioná-los para novas oportunidades que atendam a demanda de sua empresa.</p>
                )}
              </div>
            </div>
            <div className='card diversity' onClick={() => handleClick(3)}>
              <img src={diversity} alt='diversity icon' />
              <div className='card-text'>
                <h1>Diversidade e Inclusão</h1>
                {openCard === 3 && (
                  <p>Promova o impacto social e gere oportunidades de empregabilidade tech ao formar profissionais de grupos minoritários no mercado.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Method;