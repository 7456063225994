import React from 'react';
import './HomePage.css';
import Method from '../../components/Method/Method';
import Hero from '../../components/Hero/Hero';
import StudentTimeline from '../../components/StudentTimeline/StudentTimeline';
import Trails from '../../components/Trails/Trails';
import WhyProbies from '../../components/WhyProbies/WhyProbies';
import ContactUs from '../../components/ContactUs/ContactUs';
import WhoWeAre from '../../components/WhoWeAre/WhoWeAre';
import IlegraInfo from '../../components/IlegraInfo/IlegraInfo';
import ModalContact from '../../components/ModalContact/ModalContact';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Faq from '../../components/Faq/Faq';
import ProbiesInfo from '../../components/ProbiesInfo/ProbiesInfo';


const HomePage = () => {
    const [showModal, setShowModal] = React.useState(false)
    return (
        <div className='home-page'>
            <Header setShowModal={setShowModal} />
            <ToastContainer />
            {showModal &&
                <ModalContact setShowModal={setShowModal} />
            }
            <Hero setShowModal={setShowModal} />
            <div id='method'>
                <Method />
            </div>
            <StudentTimeline />
            <div id='trails'>
                <Trails />
            </div>
            <div id='whyProbies'>
                <WhyProbies />
            </div>
            <ContactUs setShowModal={setShowModal} />
            <Faq />
            <WhoWeAre setShowModal={setShowModal} />
            {/* <IlegraInfo /> */}
            <ProbiesInfo />
            <Footer />
        </div>
    )
}

export default HomePage;