import React from 'react';
import './StudentTimeline.css';
import timeline from '../../assets/timeline2.png'
import timelineMobile from '../../assets/timelineMobile.png'

function StudentTimeline() {


    return (
        <section className='student-timeline'>
            <div className='student-container'>
                <h1 className='timeline-title'>
                    Senioridade dos alunos formados
                </h1>
                <div className='timeline-container'>
                    <img src={timeline} className='desktop-image' alt='linha do tempo' />
                    <img src={timelineMobile} className='mobile-image' alt='linha do tempo' />
                </div>
            </div>
        </section>
    )
}

export default StudentTimeline;