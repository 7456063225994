import React from 'react';
import './FaqAlunos.css';
function FaqAlunos() {
    return (
        <div className='faq-container-alunos'>
            <div className='faq-content-alunos'>
                <section className='section-alunos'>
                    <h1>FAQ</h1>
                    <details className='details-alunos'>
                        <summary className='summary-alunos'>Existe algum custo para realizar a formação?</summary>
                        <p>Não. Não será cobrado taxa de inscrição e o investimento do curso será patrocinado pela(s) empresa(s) contratante(s) da formação.</p>
                    </details>
                    <details className='details-alunos'>
                        <summary className='summary-alunos'>O que eu preciso para participar do processo seletivo?</summary>
                        <p>Você necessita somente ter 17 anos ou mais, um e-mail Gmail válido, disponibilidade para realizar as atividades conforme mencionado nas informações da turma, conhecimentos básicos em lógica de programação e em alguma linguagem de programação.</p>
                    </details>
                    <details className='details-alunos'>
                        <summary className='summary-alunos'>Como acontece o processo seletivo?</summary>
                        <p>Normalmente, haverá 3 etapas de processo seletivo: triagem dos dados informados na inscrição, entrevista de competências e entrevista técnica. Estas etapas podem sofrer alguma alteração com base em alguma solicitação específica da empresa patrocinadora.</p>
                    </details>
                    <details className='details-alunos'>
                        <summary className='summary-alunos'>Qual é o conteúdo da entrevista técnica?</summary>
                        <p>É um bate-papo para que o mentor técnico possa entender seu nível de conhecimento em lógica de programação e da linguagem de programação que você conhece.</p>
                    </details>
                    <details className='details-alunos'>
                        <summary className='summary-alunos'>Quantas pessoas candidatas são selecionadas?</summary>
                        <p>Cada turma pode ter um número específico de vagas. Fique ligado(a) nas informações disponibilizadas.</p>
                    </details>
                    <details className='details-alunos'>
                        <summary className='summary-alunos'>Como acontecem as aulas?</summary>
                        <p>O curso é 100% remoto. Você receberá, através de uma plataforma de aprendizagem, uma lista de desafios a serem desenvolvidos de forma assíncrona, bem como conteúdos que te auxiliarão. A cada conteúdo uma atividade, para que você possa aprender na prática! Mentores seniores acompanharão seu aprendizado, te guiando durante todo o processo, com code review individual por atividade. Também haverão encontros síncronos com mentores e convidados, que serão agendados com antecedência, ok?</p>
                    </details>
                    <details className='details-alunos'>
                        <summary className='summary-alunos'>Posso reprovar no curso?</summary>
                        <p>Seu compromisso e desempenho fazem parte da formação. Assim, você terá prazos e critérios de desempenho mínimo para as entregas e participação mínima necessária nos encontros síncronos.</p>
                    </details>
                    <details className='details-alunos'>
                        <summary className='summary-alunos'>Além dos conhecimentos técnicos, serão abordados outros tipos de conteúdos?</summary>
                        <p>Sim! Você terá acesso a conteúdos referentes à soft skills, visão de negócio, empregabilidade e métodos de gestão de sua própria aprendizagem.</p>
                    </details>
                    <details className='details-alunos'>
                        <summary className='summary-alunos'>Ao final do curso, recebo certificado?</summary>
                        <p>Sim! Seu comprometimento é reconhecido através de um certificado.</p>
                    </details>
                </section>
            </div>
        </div>
    );
}
export default FaqAlunos;