import React from 'react';
import './ContactUs.css';

function ContactUs({ setShowModal }) {
    return (
        <section className='contact-us'>
            <div className='contact-container'>
                <h1>
                    Quer saber como podemos <br /> ajudar a sua empresa?
                </h1>
                <p>
                    Entre em contato conosco para agendar um bate-papo e conhecer mais sobre como Probies <br /> ajuda a sua empresa a formar uma equipe de tecnologia de alta performance!
                </p>
                <button onClick={() => setShowModal(true)}>
                    QUERO AGENDAR UM BATE-PAPO!
                </button>
            </div>
        </section>

    )
}

export default ContactUs;