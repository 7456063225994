import React from 'react';
import './NewClass.css';

function NewClass({ setShowModalAlunos }) {
    return (
        <section className='newClass-alunos'>
            <h1>
                Quer saber quando uma nova turma irá abrir?
            </h1>
            <button onClick={() => setShowModalAlunos(true)}>
                INSCREVA-SE
            </button>
        </section>

    )
}

export default NewClass;