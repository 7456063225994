import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import logo2 from '../../assets/probies-laranja2.png';
import '../../components/Header/Header.css';
import { Link } from 'react-router-dom';

function Header({ setShowModal }) {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0
        });
    };
    const scrollToSection = (id) => {
        document.querySelector(`#${id}`).scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <header>
            <div className='header-content'>
                <div className='logo-container'>
                    <img src={logo2} alt='probies' className='logo' onClick={scrollToTop} />
                </div>
                <nav>
                    <ul>
                        <li className='label-1'>
                            <a onClick={() => scrollToSection('method')}>MÉTODO</a>
                        </li>
                        <li className='label-2'>
                            <a onClick={() => scrollToSection('trails')}>TRILHAS</a>
                        </li>
                        <li className='label-3'>
                            <a onClick={() => scrollToSection('whyProbies')}>BENEFÍCIOS</a>
                        </li>
                        <li className='label-4'>
                            <Link to='/para-alunos'>PARA ALUNOS</Link>
                        </li>
                        <li>
                            <a>
                                <button className='nav-btn' onClick={() => setShowModal(true)}>AGENDAR BATE-PAPO</button>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header >
    )
}
export default Header;