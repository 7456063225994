import React from 'react';
import './WhoWeAre.css';
import probiesWhoWeAre from '../../assets/probiesWhoWeAre.png';

function WhoWeAre({ setShowModal }) {
    return (
        <section className='whoWeAre'>
            <div className='whoWeAre-content'>
                <img src={probiesWhoWeAre} alt='logo probies' />
                <div className='whoWeAre-container'>
                    <h1>
                        Quem somos
                    </h1>
                    <p>
                        Não é surpresa: a falta de profissionais de tecnologia é uma grande pedra no sapato de toda empresa. Quem tem profissionais qualificados se mantém no topo. Quem não tem, <strong>fica para trás.</strong>
                    </p>
                    <p>
                        Isso não é apenas sobre conhecimento técnico. É sobre ter pessoas com habilidades de se comunicar, analisar cenários e tomar decisões. E a verdade é...
                    </p>
                    <p>
                        O que você busca, o mercado não tem à disposição. <br /> O que você precisa, o mercado não produziu.
                    </p>
                    <p>
                        Por isso, lançamos o programa que vai formar profissionais de tecnologia para sua empresa. <strong>E só quem realmente entende do assunto pode preparar profissionais de alta performance.</strong>
                    </p>
                    <p>
                        Quer excelência? Somos a referência! Com este programa, queremos devolver a sua competitividade <br /> frente à concorrência.
                    </p>
                    <p>
                        <strong>Te inspiramos a transformar a realidade:</strong> <br />De pessoas. Da sociedade. Do seu negócio.<br />Pense Além.
                    </p>
                    <button onClick={() => setShowModal(true)}>
                        FALAR COM UM CONSULTOR
                    </button>
                </div>
            </div>
        </section>
    )
}

export default WhoWeAre;