import React from 'react';
import './TrailsAlunos.css';
import frontendImg from '../../assets/frontendImg.png';
import dataImg from '../../assets/dataImg.png';
import backendImg from '../../assets/backendImg.png';
function TrailsAlunos() {
    return (
        <section className='trails-alunos'>
            <div className='trails-alunos-container'>
                <div className='text-container-alunos'>
                    <h1>
                        Formação tecnológica <br />completa e<strong> sem custos</strong>
                    </h1>
                    <p>
                        As atividades são dinâmicas, interativas e 100% remotas. A formação conta com mentorias individuais e coletivas<br />
                        realizadas por profissionais tech atuantes no mercado. Soft skills também são contempladas: pensamento<br />
                        analítico e criativo, resiliência e flexibilidade, motivação e autoconsciência, curiosidade e aprendizado contínuo,<br />
                        empatia e escuta ativa. <strong>São essas interações que garantem que vocês se tornem profissionais prontos para<br />
                        atender às demandas e desafios que possam surgir no mercado.</strong></p>
                </div>
                <h1 className='trails-title'>
                    Principais Trilhas
                </h1>
                <div className='trails-container-alunos'>
                    <div className='card-trail'>
                        <img className='card-img' src={frontendImg} alt='frontend imagem' />
                        <h1>Trilha Frontend <br />Engineering</h1>
                    </div>
                    <div className='card-trail'>
                        <img className='card-img' src={dataImg} alt='engenharia de dados imagem' />
                        <h1>Data Analysis and <br />Business Data Engineering</h1>
                    </div>
                    <div className='card-trail'>
                        <img className='card-img' src={backendImg} alt='backend imagem' />
                        <h1>Trilha Backend <br />Engineering</h1>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default TrailsAlunos;