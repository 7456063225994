import React from 'react';
import probies from '../../assets/probiesFooter.png';
import ilegra from '../../assets/logo-ilegra.png';
import arrow from '../../assets/arrowToTop.svg';
import './FooterAlunos.css';

function FooterAlunos() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0
    });
  };
  return (
    <div className='footer-alunos'>
      <div className="footer-alunos-content">
        <div className="text-footer">
          <img src={probies} className="probies-img" alt='Probies é uma marca Ilegra' />
          <img src={ilegra} className="ilegra-logo-img" alt='Logo Ilegra' />
        </div>
        <button className="scrollToTopButton-alunos" onClick={scrollToTop}>
          <img src={arrow} className="arrow-white" alt="Seta para cima" />
        </button>
      </div>
    </div>
  );
}
export default FooterAlunos;