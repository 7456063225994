  import axios from 'axios';
  
  export async function sendEmail(userContact) {
  console.log('Backend API URL:', process.env.REACT_APP_BACKEND_API);
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/dev/submit`,
        {
          name: userContact.name,
          position: userContact.position,
          email: userContact.email,
          department: userContact.department,
          phone: userContact.phone,
          segment: userContact.segment,
          company: userContact.company,
          employeesNumber: userContact.employeesNumber,
          comments: userContact.comments,
          origin: "Site Probies"
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data)

      if (response.data) {
        console.log("Email sent successfully:", response.data);
        return response.data;
      } else {
        const errorMessage = `Error sending email for ${userContact.name}: ${response.data.error}`;
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Error making request to backend", error);
      throw new Error(error instanceof Error ? error.message : "Unknown error");
    }
  }

  export const sendEmailAlunos = (userContact) => {
      axios.post(`${enviroments.apiUrl}/conversions?api_key=${enviroments.apiKey}`, {
          'event_type': 'CONVERSION',
          'event_family': 'CDP',
          'payload': {
              'conversion_identifier': 'Probies',
              'email': userContact.email,
              'name': userContact.name,
              'mobile_phone': userContact.phone,
              'cf_students_interestarea': userContact.interestArea,
          }
      })
  }