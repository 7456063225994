import React from "react";
import "./Trails.css";
import frontendImg from "../../assets/frontendImg.png";
import dataImg from "../../assets/dataImg.png";
import backendImg from "../../assets/backendImg.png";

function Trails() {
  return (
    <section className="trails">
      <div className="trails-container">
        <h1 className="trails-title">principais trilhas:</h1>
        <div className="trails-container">
          <div className="card-trail">
            <h1>
              Trilha Frontend <br />
              Engineering
            </h1>
            <img className="card-img" src={frontendImg} alt="frontend imagem" />
          </div>
          <div className="card-trail">
            <h1>
            Trilha Backend <br />
            Engineering
            </h1>
            <img className="card-img" src={frontendImg} alt="frontend imagem" />
          </div>
          <div className="card-trail">
            <h1>
              Trilha <br />
              Blockchain
            </h1>
            <img className="card-img" src={frontendImg} alt="frontend imagem" />
          </div>
          <div className="card-trail">
            <h1>
              Trilha <br />
              QA
            </h1>
            <img className="card-img" src={frontendImg} alt="frontend imagem" />
          </div>
        </div>
        <div className="second-line">
            <div className="card-trail">
            <h1>
              Data Analysis and Business <br />
              Data Engineering
            </h1>
            <img className="card-img" src={dataImg} alt="backend imagem" />
          </div>
          <div className="card-trail">
            <h1>
              Trilha <br />
              Power BI
            </h1>
            <img className="card-img" src={dataImg} alt="backend imagem" />
          </div>
          <div className="card-trail">
            <h1>
              Trilha <br />
              IA
            </h1>
            <img className="card-img" src={frontendImg} alt="frontend imagem" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Trails;