import React from 'react';
import './Faq.css';

function Faq() {

    return (
        <div className='faq-container-empresas'>
            <div className='faq-content'>
                <section className='section-empresas'>
                    <h1>FAQ</h1>
                    <details className='details-empresas'>
                        <summary className='summary-empresas'>Quais as entregas do Probies para a empresa?</summary>
                        <p className='paragraph-empresas'>Profissionais Júnior+ iniciados! Mais preparados para a rotina de atividades diárias, formados com as boas práticas de mercado e com alto potencial de crescimento futuro.</p>
                    </details>
                    <details className='details-empresas'>
                        <summary className='summary-empresas'>Como funciona?</summary>
                        <ol className='paragraph-empresas'>
                            <li>Captação dos alunos</li>
                            <li>Processo Seletivo: triagem CV, entrevista competências e técnicas</li>
                            <li>Mentorias individuais e coletivas</li>
                            <li>Code reviews individuais</li>
                            <li>Conteúdos de ponta</li>
                            <li>Convidados Especiais (soft skills)</li>
                            <li>Gestão do Programa: acompanhamento individual dos alunos</li>
                            <li>Ambiente e Plataforma de Aprendizagem</li>
                            <li>Avaliação de desempenho individual por trilha realizada</li>
                            <li>Certificado de conclusão do curso</li>
                        </ol>
                    </details>
                    <details className='details-empresas'>
                        <summary className='summary-empresas'>As turmas possuem limitação de participantes?</summary>
                        <p className='paragraph-empresas'>Sim! Pela característica de formação individual, as turmas são para até 25 alunos. Com exceção do Módulo inicial dos programas de Reskilling que, por se tratar de sensibilização e capacitação essencial, podemos trabalhar com até 2k de participantes.</p>
                    </details>
                    <details className='details-empresas'>
                        <summary className='summary-empresas'>Os alunos possuem vínculo empregatício com a ilegra ou empresa contratante?</summary>
                        <p className='paragraph-empresas'>Não. Probies é um curso livre, gratuito para o aluno. Ou seja, o aluno assina um contrato formalizando esta modalidade, bem como os critérios de desempenho para sua aprovação final.</p>
                    </details>
                    <details className='details-empresas'>
                        <summary className='summary-empresas'>Há um compromisso de contratação dos alunos formados?</summary>
                        <p className='paragraph-empresas'>Não. O contrato com os alunos e a comunicação do programa deixa claro que é um programa gratuito de formação e que existe a “possibilidade”, de acordo com seu desempenho e fit cultural, de o aluno ser contratado pela empresa ou pool de empresas contratantes do programa. Caso o aluno não seja contratado, suas chances de contratação pelo mercado aumentam substancialmente pela formação realizada.</p>
                    </details>
                    <details className='details-empresas'>
                        <summary className='summary-empresas'>Qual a diferença entre reskilling e upskilling?</summary>
                        <p className='paragraph-empresas'>Reskilling é a formação para colaboradores da empresa a fim de oportunizar uma nova carreira, na área de tecnologia. Upskilling é a formação de uma tecnologia específica para colaboradores da área de TI da empresa contratante.</p>
                    </details>
                    <details className='details-empresas'>
                        <summary className='summary-empresas'>Quais as trilhas de formação disponíveis?</summary>
                        <p className='paragraph-empresas'>As trilhas mais solicitadas são Engenharia Backend, Engenharia Frontend, Engenharia de Dados e Análise de Dados. Entretanto, trilhas podem ser desenvolvidas a partir da solicitação do cliente, caso esteja dentro da expertise dos profissionais da ilegra.</p>
                    </details>
                    <details className='details-empresas'>
                        <summary className='summary-empresas'>A ilegra é uma empresa de educação?</summary>
                        <p className='paragraph-empresas'>Nos entendemos como uma empresa de TI para TI. Acreditamos que só quem realmente entende do assunto pode preparar profissionais focados de alta performance.</p>
                    </details>
                    <details className='details-empresas'>
                        <summary className='summary-empresas'>Probies pode ser contratada na modalidade White Label?</summary>
                        <p className='paragraph-empresas'>Sim! Nossos serviços podem ser realizados com o branding de sua empresa.</p>
                    </details>
                </section>
            </div>
        </div>
    );
}

export default Faq;