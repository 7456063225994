import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineClose } from 'react-icons/ai';
import { BsFillPersonFill, BsBagFill } from 'react-icons/bs';
import InputMask from 'react-input-mask';
import { BiBuildings } from 'react-icons/bi';
import { CgPhone } from 'react-icons/cg';
import { MdEmail } from 'react-icons/md';
import { HiUsers } from 'react-icons/hi';
import { toast } from 'react-toastify';
import { sendEmail } from '../../services/integrationApi';
import 'react-toastify/dist/ReactToastify.css';
import './ModalContact.css';

const segmentOptionsPt = [
    "Indústria",
    "Varejo",
    "Atacado",
    "Serviços",
    "Tecnologia",
    "Bancos/Financeiro",
    "Seguros",
    "Comunicação",
    "Saúde",
    "Hoteleiro",
    "Governo",
    "Educação",
];

function ModalContact({ setShowModal }) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const onSubmit = (userContact) => {
        if (isChecked) {
            sendEmail(userContact);
            showToast(true);
            setIsFormSubmitted(true);
            setTimeout(() => {
                setShowModal(false);
            }, 2000);
        } else {
            showToast(false);
        }
    };

    const closeAndSubmit = () => {
        if (!isChecked) {
            console.log('Por favor, concorde com os termos e condições.');
        } else if (!isFormSubmitted) {
            console.log('Por favor, preencha todos os campos de entrada.');
        }
    };

    const showToast = (success) => {
        if (success) {
            toast.success('Obrigado! Fique atento, entraremos em contato em breve.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else {
            toast.warn("Para enviar a solicitação é necessário aceitar os termos de serviço.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    };

    return (
        <div className='modalContainer'>
            <div className='modal'>
                <div className='closeButtonContainer'>
                    <AiOutlineClose
                        onClick={() => setShowModal(false)}
                        size={20}
                        color={'#7e34f4'}
                        className='closeButton'
                    />
                </div>
                <h3>
                    Preencha os campos abaixo para que um dos nossos consultores entre em contato com você:
                </h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='rowContainer'>
                        <div className={`fieldContainer ${errors.name ? 'invalid' : ''}`}>
                            <div className='iconContainer'>
                                <BsFillPersonFill size={23} color='#fff' />
                            </div>
                            <input
                                placeholder='Nome*'
                                {...register('name', {
                                    required: 'Campo obrigatório',
                                    pattern: {
                                        value: /^[a-zA-ZÀ-ÿ\s]*$/,
                                        message: 'Apenas letras são permitidas',
                                    },
                                })}
                            />
                        </div>
                        {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                    </div>
                    <div className='rowContainer'>
                        <div className={`fieldContainer ${errors.company ? 'invalid' : ''}`}>
                            <div className='iconContainer'>
                                <BiBuildings size={23} color='#fff' />
                            </div>
                            <input
                                placeholder='Empresa*'
                                {...register('company', { required: 'Campo obrigatório' })}
                            />
                        </div>
                        {errors.company && <span className='errorMessage'>{errors.company.message}</span>}
                    </div>
                    <div className='rowContainer'>
                        <div className={`fieldContainer ${errors.email ? 'invalid' : ''}`}>
                            <div className='iconContainer'>
                                <MdEmail size={23} color='#fff' />
                            </div>
                            <input
                                placeholder='E-mail corporativo*'
                                {...register('email', {
                                    required: 'Campo obrigatório',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'E-mail inválido (exemplo@exemplo.com)',
                                    },
                                })}
                            />
                        </div>
                        {errors.email && <span className='errorMessage'>{errors.email.message}</span>}
                    </div>
                    <div className='rowContainer'>
                        <div className={`fieldContainer ${errors.phone ? 'invalid' : ''}`}>
                            <div className='iconContainer'>
                                <CgPhone size={23} color='#fff' />
                            </div>
                            <InputMask
                                mask="(99) 99999-9999"
                                {...register('phone', {
                                    required: 'Campo obrigatório',
                                    pattern: {
                                        value: /^[(]\d{2}[)]\s\d{5}-\d{4}$/,
                                        message: 'Telefone inválido',
                                    },
                                })}
                            >
                                {(inputProps) => <input {...inputProps} placeholder="Telefone*" />}
                            </InputMask>
                        </div>
                        {errors.phone && <span className='errorMessage'>{errors.phone.message}</span>}
                    </div>
                    <div className='rowContainer'>
                        <div className={`fieldContainer ${errors.segment ? 'invalid' : ''}`}>
                            <div className='iconContainer'>
                                <BsBagFill size={23} color='#fff' />
                            </div>
                            <select
                                {...register('segment', {
                                    required: 'Campo obrigatório',
                                })}
                                required
                            >
                                <option value='' disabled selected hidden>Segmento*</option>
                                {segmentOptionsPt.map((option, index) => (
                                    <option key={index} value={option}>{option}</option>
                                ))}
                            </select>
                        </div>
                        {errors.segment && <span className='errorMessage'>{errors.segment.message}</span>}
                    </div>
                    <div className='rowContainer'>
                        <div className={`fieldContainer ${errors.employees ? 'invalid' : ''}`}>
                            <div className='iconContainer'>
                                <HiUsers size={23} color='#fff' />
                            </div>
                            <input
                                placeholder='Número de colaboradores*'
                                {...register('employeesNumber', {
                                    required: 'Campo obrigatório',
                                    pattern: {
                                        value: /^\d+$/,
                                        message: 'Apenas números são permitidos',
                                    },
                                })}
                            />
                        </div>
                        {errors.employees && (
                            <span className='errorMessage'>{errors.employees.message}</span>
                        )}
                    </div>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                        id="agreeCheckbox"
                    />
                    <label htmlFor="agreeCheckbox"> Eu concordo em receber comunicações por e-mail sobre <br />serviços e outros conteúdos de probies.</label>
                    <button
                        type="submit"
                        className={`sendButton ${isFormSubmitted ? 'success' : ''}`}
                        disabled={isFormSubmitted}
                        onClick={closeAndSubmit}
                    >
                        {isFormSubmitted ? 'Enviado' : 'Enviar'}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ModalContact;
