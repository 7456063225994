import React from 'react';
import ContactUsAlunos from '../../components/ContactUsAlunos/ContactUsAlunos';
import FaqAlunos from '../../components/FaqAlunos/FaqAlunos';
import FooterAlunos from '../../components/FooterAlunos/FooterAlunos';
import HeaderAlunos from '../../components/HeaderAlunos/HeaderAlunos';
import HeroAlunos from '../../components/HeroAlunos/HeroAlunos';
import ModalContactAlunos from '../../components/ModalContactAlunos/ModalContactAlunos';
import NewClass from '../../components/NewClass/NewClass';
import RegistrationsDatesAlunos from '../../components/RegistrationsDatesAlunos/RegistrationsDatesAlunos';
import TrailsAlunos from '../../components/TrailsAlunos/TrailsAlunos';
import WhyProbiesAlunos from '../../components/WhyProbiesAlunos/WhyProbiesAlunos';
import './ParaAlunos.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProbiesInfo from '../../components/ProbiesInfo/ProbiesInfo';


const ParaAlunos = () => {
    const [showModalAlunos, setShowModalAlunos] = React.useState(false)
    return (
        <main>
            <HeaderAlunos setShowModalAlunos={setShowModalAlunos} />
            <ToastContainer />
            {showModalAlunos &&
                <ModalContactAlunos setShowModalAlunos={setShowModalAlunos} />
            }
            <HeroAlunos setShowModalAlunos={setShowModalAlunos} />
            <div id='trailsAlunos'>
                <TrailsAlunos />
            </div>
            {/* <RegistrationsDatesAlunos /> */}
            <NewClass setShowModalAlunos={setShowModalAlunos} />
            <div id='whyProbiesAlunos'>
                <WhyProbiesAlunos />
            </div>
            <ContactUsAlunos setShowModalAlunos={setShowModalAlunos} />
            <div id='faq'>
                <FaqAlunos />
            </div>
            <div id='probiesInfo'>
                <ProbiesInfo />
            </div>
            <FooterAlunos />
        </main>
    )
}

export default ParaAlunos;