import React from 'react';
import instagramProbies from '../../assets/instagramProbies.png'
import linkedinProbies from '../../assets/linkedinProbies.png'
import './ProbiesInfo.css';

function ProbiesInfo() {
    return (
        <section className='probiesInfo'>
            <div className='probiesInfo-content'>
                <div className='probiesInfo-container'>
                    <h1>
                        Somos Probies
                    </h1>
                    <p>
                        <strong>Probies é uma marca ilegra.</strong> Uma empresa global de design, inovação e software com mais de 20<br />
                        anos de mercado. Somos movidos pelo desejo de criar soluções que melhorem a vida de nossos<br />
                        clientes e seus usuários, combinando tecnologia com a paixão das pessoas.</p>
                    <div className="probiesInfo-social-links">
                        <a href='https://www.instagram.com/somosprobies/?igshid=MmIzYWVlNDQ5Yg%3D%3D&utm_campaign=rh_acontece_21072023&utm_medium=email&utm_source=RD%20Station' target='_blank'>
                            <img src={instagramProbies} alt="instagram probies" />
                        </a>
                        <a href='https://www.linkedin.com/company/somosprobies/?utm_campaign=rh_acontece_21072023&utm_medium=email&utm_source=RD+Station' target='_blank'>
                            <img src={linkedinProbies} alt="linkedin probies" />
                        </a>
                    </div>
                    <a href='https://ilegra.com/politica-de-privacidade/' target='_blank'>
                        <h3>Política de Privacidade</h3>
                    </a>
                </div>
            </div>
        </section>
    )
}
export default ProbiesInfo;