import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import logo2 from '../../assets/probies-rosa2.png';
import '../../components/HeaderAlunos/HeaderAlunos.css';
import { Link } from 'react-router-dom';
function HeaderAlunos({ setShowModalAlunos }) {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0
        });
    };
    const scrollToSection = (id) => {
        document.querySelector(`#${id}`).scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <header className='header-alunos'>
            <div className='header-alunos-content'>
                <div className='logo-container-alunos'>
                    <img src={logo2} alt='probies' className='logo' onClick={scrollToTop} />
                </div>
                <nav>
                    <ul>
                        <li className='label-01'>
                            <a onClick={() => scrollToSection('trailsAlunos')}>FORMAÇÃO</a>
                        </li>
                        <li className='label-02'>
                            <a onClick={() => scrollToSection('whyProbiesAlunos')}>BENEFÍCIOS</a>
                        </li>
                        <li className='label-03'>
                            <a onClick={() => scrollToSection('faq')}>FAQ</a>
                        </li>
                        <li className='label-04'>
                            <Link to='/'>PARA EMPRESAS</Link>
                        </li>
                        <li>
                            <a>
                                <button className='nav-btn-alunos' onClick={() => setShowModalAlunos(true)}>QUERO ME INSCREVER</button>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header >
    )
}
export default HeaderAlunos;