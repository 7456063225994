import React from 'react';
import HomePage from './page/Home/HomePage';
import ModalContact from './components/ModalContact/ModalContact';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import ParaAlunos from './page/ParaAlunos/ParaAlunos';

function App() {
  const [showModal, setShowModal] = React.useState(false)

  return (
    <BrowserRouter>
      <div className="App">
        {showModal &&
          <ModalContact setShowModal={setShowModal} />
        }
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/para-alunos' element={<ParaAlunos />} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App;
