import React from 'react';
import './Hero.css';

function Hero({ setShowModal }) {
    return (
        <section className="hero">
            <div className="hero-container">
                <h1>
                    <strong> Transformamos newbies em probies:</strong>  juniores de alta performance para sua empresa
                </h1>
                <p>
                    Ter profissionais de tecnologia é a chave para o sucesso dos negócios.<br />
                    Com Probies, sua empresa elimina a necessidade de procurar profissionais juniores no mercado e as incertezas de contratação.
                </p>
                <button onClick={() => setShowModal(true)}>
                    QUERO CONTRATAR
                </button>
            </div>
        </section>
    )
}

export default Hero;