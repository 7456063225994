import React from 'react';
import './ContactUsAlunos.css';

function ContactUsAlunos({ setShowModalAlunos }) {
    return (
        <section className='contact-us-alunos'>
            <h1>
                Te inspiramos a transformar<br /> a sua realidade!
            </h1>
            <p>
                Com este programa, queremos desenvolver sua competitividade no mercado de trabalho. Não perca a oportunidade de<br /> transformar a sua carreira de forma gratuita! Clique no botão abaixo para se inscrever.
            </p>
            <button onClick={() => setShowModalAlunos(true)}>
                QUERO SER PRO DE VERDADE
            </button>
        </section>

    )
}

export default ContactUsAlunos;